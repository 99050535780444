import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import AppHeader from '~/js/components/AppHeader'
import ControlButtonsDocument from '~/js/components/ControlButtonsDocument'
import ModalControlCode from '~/js/components/ModalControlCode'
import ModalRemove from '~/js/components/ModalRemove'
import ModalShare from '~/js/components/ModalShare'
import SectionDocumentInformation from '~/js/components/SectionDocumentInformation'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import * as documentService from '../../../../services/user/document'
import Members from '~/js/components/Members'
import History from '../../../../components/History'
import ModalDocumentViewer from '~/js/components/ModalDocumentViewer'
import apiClient from '~/js/utils/apiClientDocs'
import DocumentComments from '../../../../components/DocumentComments'
import ModalDocumentMigrationCompany from '../../../../components/ModalDocumentMigrationCompany'
import * as workspaceService from '../../../../services/user/workspace'
import DocumentViewer from '~/js/components/DocumentViewer'
import ModalZealIdQrCode from '../../../../components/ModalZealIdQrCode'
import ModalEmailConfirmation from '~/js/components/ModalEmailConfirmation'
import { AUTH_TYPE_ZEALID, validationInfo } from '~/js/services/user/user'
import ModalValidationLimitReached from '~/js/components/ModalValidationLimitReached'
import ModalSignaturesCta from '../../../../components/ModalSignaturesCta/ModalAdditionalCta'
import ModalAdditionalSignatures from '../../../../components/ModalAdditionalSignatures/ModalAdditionalSignatures'
import ModalRejectReason from '../../../../components/ModalRejectReason/ModalRejectReason'
import AdditionalPerks from '~/js/components/AdditionalPerks'
import { DOCUMENT_BILLING_TYPE_SIGNER, DOCUMENT_FORMAT_PDF } from '~/js/models/Document'
import { SIGNATURE_TYPE_GLOBAL } from '~/js/models/Product'
import withZealIdQrHtml from '~/js/hoc/withZealIdQrHtml'
import { AUTH_TYPE_MOBILEID, AUTH_TYPE_SIMPLYSIGN, AUTH_TYPE_SMARTCARD, AUTH_TYPE_SMARTID, AUTH_TYPE_LTID } from '../../../../services/user/user'
import Loader from '../../../../components/Loader'
import Modal from '../../../../components/Modal'
import ModalSimplySignPin from '../../../../components/ModalSimplySignPin/ModalSimplySignPin'
import { findSignatureProducts } from '../../../../utils/common'
import SectionErrorInformation from '../../../../components/SectionErrorInformation'

const controlCodeTimeLimit = 90

class DocumentPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      document: undefined,
      documentErrors: [],
      isDeadline: undefined,
      isSignForbidden: undefined,
      canInviteSigner: false,
      modalControlCode: {
        active: false,
        authType: undefined,
        controlCode: undefined,
        closeCallback: () => { },
      },
      modalRemoveActive: false,
      modalShareActive: false,
      modalPdfViewActive: false,
      modalAssignDocumentCategoryActive: false,
      modalMigrateDocumentActive: false,
      modalSignaturesCtaActive: false,
      modalValidationLimitActive: false,
      modalRemoveMember: {
        active: false,
        documentMemberIndex: undefined
      },
      modalAdditionalSignatures: {
        active: false,
        products: [],
      },
      isSigning: false,
      isReviewing: false,
      isSignaturesSufficient: undefined,
      redirectTo: undefined,
      historyRecords: [],
      isWidget: !!new URL(location.href).searchParams.get('widget'),
      documentComments: [],
      documentBillingType: undefined,
      modalZealIdDocumentSign: {
        active: false,
        type: 'authorize',
        closeCallback: () => {},
      },
      modalEmailConfirmation: {
        active: false,
        closeCallback: () => {},
      },
      modalDenyDocumentConfirmation: {
        active: false,
      },
      isSaving: false,
      zealIdAccessToken: undefined,
      zealIdSigningData: undefined,
      currentZealStage: 1,
      qrHashCalled: 0,
      signHashCalled: 0,
      deadline: null,
      isSimplySignLoading: false,
      modalSimplySignPin: {
        active: false,
      },
      simplySignPinNo: undefined
    }

    this.addComment = this.addComment.bind(this)
    this.addPurposeInMember = this.addPurposeInMember.bind(this)
    this.addSigner = this.addSigner.bind(this)
    this.addSigners = this.addSigners.bind(this)
    this.assignCategoriesToDocument = this.assignCategoriesToDocument.bind(this)
    this.checkIfSigningAllowed = this.checkIfSigningAllowed.bind(this)
    this.closeAdditionalSignaturesModal = this.closeAdditionalSignaturesModal.bind(this)
    this.fetchCompanies = this.fetchCompanies.bind(this)
    this.fetchDocumentBilling = this.fetchDocumentBilling.bind(this)
    this.fetchDocumentComments = this.fetchDocumentComments.bind(this)
    this.fetchDocumentHistory = this.fetchDocumentHistory.bind(this)
    this.fetchDocumentInfo = this.fetchDocumentInfo.bind(this)
    this.finalizeSigning = this.finalizeSigning.bind(this)
    this.getDocumentId = this.getDocumentId.bind(this)
    this.handleSigningError = this.handleSigningError.bind(this)
    this.handleSigningSuccess = this.handleSigningSuccess.bind(this)
    this.hideModalControlCode = this.hideModalControlCode.bind(this)
    this.initializeDocumentSigning = this.initializeDocumentSigning.bind(this)
    this.initializeSimplySignSigning = this.initializeSimplySignSigning.bind(this)
    this.isContentShrink = this.isContentShrink.bind(this)
    this.migrateDocument = this.migrateDocument.bind(this)
    this.migrateDocumentToWorkspace = this.migrateDocumentToWorkspace.bind(this)
    this.onAssignCategoriesClick = this.onAssignCategoriesClick.bind(this)
    this.onBuyClick = this.onBuyClick.bind(this)
    this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this)
    this.onReviewButtonClick = this.onReviewButtonClick.bind(this)
    this.onCtaBuyAdditionalSignatures = this.onCtaBuyAdditionalSignatures.bind(this)
    this.onDenyButtonClick = this.onDenyButtonClick.bind(this)
    this.onDocumentDownloadClick = this.onDocumentDownloadClick.bind(this)
    this.onDocumentMigrateClick = this.onDocumentMigrateClick.bind(this)
    this.onDocumentRemoveClick = this.onDocumentRemoveClick.bind(this)
    this.onDocumentShareClick = this.onDocumentShareClick.bind(this)
    this.onDocumentViewClick = this.onDocumentViewClick.bind(this)
    this.onModalDenyDocumentConfirmationSubmit = this.onModalDenyDocumentConfirmationSubmit.bind(this)
    this.onModalGetSignatures = this.onModalGetSignatures.bind(this)
    this.onModalRemoveDocumentClick = this.onModalRemoveDocumentClick.bind(this)
    this.onRemoveMemberClick = this.onRemoveMemberClick.bind(this)
    this.onSignButtonClick = this.onSignButtonClick.bind(this)
    this.onValidateDocumentClick = this.onValidateDocumentClick.bind(this)
    this.removeComment = this.removeComment.bind(this)
    this.removeMember = this.removeMember.bind(this)
    this.setCompanies = this.setCompanies.bind(this)
    this.setSimplySignPinNo = this.setSimplySignPinNo.bind(this)
    this.showModalControlCode = this.showModalControlCode.bind(this)
    this.showStatusMessage = this.showStatusMessage.bind(this)
    this.unassignDocumentCategory = this.unassignDocumentCategory.bind(this)
    this.updateDocumentMemberPhone = this.updateDocumentMemberPhone.bind(this)
    this.updateMember = this.updateMember.bind(this)
    this.showEmailConfirmationModal = this.showEmailConfirmationModal.bind(this)

    this.fetchDocumentHistory()
    this.fetchDocumentComments()
    this.fetchDocumentInfo()
    this.fetchCompanies()
    this.fetchDocumentBilling()
  }

  fetchDocumentInfo() {
    return documentService
      .getInfo(this.getDocumentId())
      .then(data => new Promise(res => {
        this.setState({ document: data, documentErrors: data?.errors || [], purpose: data.purpose, deadline: data.deadline }, res)
      }))
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchDocumentHistory() {
    documentService
      .getHistoryRecords(this.getDocumentId())
      .then(data => {
        this.setState({
          historyRecords: data
        })
      })
      .catch(error => {
        this.showStatusMessage(SM_TYPE_ERROR, error.message)
      })
  }

  fetchDocumentComments() {
    documentService
      .getDocumentComments(this.getDocumentId())
      .then(data => {
        this.setState({
          documentComments: data
        })
      })
      .catch(error => {
        this.showStatusMessage(SM_TYPE_ERROR, error.message)
      })
  }

  fetchDocumentBilling() {
    const { showStatusMessage } = this.props

    documentService
      .getDocumentBilling(this.getDocumentId())
      .then(({ data }) => this.setState({
        documentBillingType: data.finalBillingType,
        signatureType: data.finalSignatureType,
        isSignaturesSufficient: data.isSignaturesSufficient,
      }))
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  /**
     * @returns {string}
     */
  getDocumentId() {
    return this.state.document ? this.state.document.uuid : this.props.match.params.documentId
  }

  onDocumentDownloadClick() {
    const url = `/api/v1/${getUserWorkspace()}/document/${this.getDocumentId()}/download`

    window.open(url, '_blank')
    this.fetchDocumentHistory()
  }

  onDocumentMigrateClick() {
    this.setState({ modalMigrateDocumentActive: true })
  }

  onDocumentRemoveClick() {
    this.setState({ modalRemoveActive: true })
  }

  onDocumentShareClick() {
    this.setState({ modalShareActive: true })
  }

  onDocumentViewClick() {
    this.setState({ modalPdfViewActive: true })
  }

  onAssignCategoriesClick() {
    this.setState({ modalAssignDocumentCategoryActive: true })
  }

  showStatusMessage(type, message) {
    this.props.showStatusMessage(type, message)
  }

  onRemoveMemberClick(index) {
    this.setState({
      modalRemoveMember: {
        active: true,
        documentMemberIndex: index,
      },
    })
  }

  onValidateDocumentClick() {
    const { promise } = validationInfo(this.props.user.id)

    promise
      .then(data => data.data.canValidate ? data : Promise.reject(data))
      .then(() => {
        localStorage.setItem('validation_files', JSON.stringify([this.getDocumentId()]))
        const url = generatePath(ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW, { documentId: this.getDocumentId() })
        this.props.history.push(url)
      })
      .catch(() => {
        this.setState({ modalValidationLimitActive: true })
      })
  }

  removeMember(index) {
    const { t } = this.props
    const { document } = this.state
    const members = document.members

    documentService
      .removeMember(this.state.document.uuid, members[index].id)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.signer_was_successfully_removed'))
      })
      .finally(() => {
        this.fetchDocumentInfo()
        this.setState({
          modalRemoveMember: {
            active: false,
            documentMemberIndex: undefined
          }
        })
      })
  }

  showModalControlCode(authType, controlCode, closeCallback) {
    this.setState({
      modalControlCode: {
        active: true,
        authType,
        controlCode,
        closeCallback,
      }
    })
  }

  hideModalControlCode() {
    this.setState({
      modalControlCode: {
        active: false,
      }
    })
  }

  onModalRemoveDocumentClick() {
    const { t } = this.props

    documentService
      .remove(this.getDocumentId())
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_removed'))
        setTimeout(() => {
          this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_DOCUMENTS) })
        }, 1000)
      })
      .catch(err => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
      .finally(() => {
        this.setState({ modalRemoveActive: false })
      })
  }

  onModalGetSignatures() {
    this.setState({ modalSignaturesCtaActive: true })
  }

  closeAdditionalSignaturesModal() {
    this.setState({ modalAdditionalSignatures: { active: false, products: [] } })
  }

  onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const product = this.props.signatureProducts.find(itm => itm.id === selectedProductId)

    this.props.clearCart()
    this.props.addCartItem(product)
    this.props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  onCtaBuyAdditionalSignatures() {
    const { signatureType } = this.state
    const { signatureProducts, user } = this.props
    const nameIncludes = signatureType === SIGNATURE_TYPE_GLOBAL ? 'global' : user.subscription

    this.setState({
      modalAdditionalSignatures: {
        active: true,
        products: signatureProducts.filter(p => p.name.includes(nameIncludes)),
      },
      modalSignaturesCtaActive: false,
      modalValidationLimitActive: false,
    })
  }

  showEmailConfirmationModal() {
    this.setState({
      modalEmailConfirmation: {
        active: true,
        closeCallback: this.showEmailConfirmationModal,
      }
    })
  }

  hideEmailConfirmationModal() {
    this.setState({
      modalEmailConfirmation: {
        active: false
      }
    })
  }

  onSignButtonClick() {
    this.fetchDocumentInfo()
      .then(this.checkIfSigningAllowed)
      .then(this.initializeDocumentSigning)
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onConfirmButtonClick() {
    const { t } = this.props

    documentService
      .confirmDocument(this.getDocumentId())
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_confirmed'))
        this.fetchDocumentInfo()
        this.props.fetchUser()
      })
      .catch(err => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  onReviewButtonClick() {
    const { t } = this.props

    documentService
      .reviewDocument(this.getDocumentId())
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_reviewed'))
        this.fetchDocumentInfo()
        this.props.fetchUser()
      })
      .catch(err => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  onDenyButtonClick() {
    this.setState({ modalDenyDocumentConfirmation: { active: true } })
  }

  onModalDenyDocumentConfirmationSubmit(values) {
    const { t } = this.props

    documentService
      .denyDocument(this.getDocumentId(), {
        confirmationDenyReason: values.reasonText
      })
      .then(() => {
        this.setState({ modalDenyDocumentConfirmation: { active: false } })
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_denied'))
        this.fetchDocumentInfo()
        this.props.fetchUser()
      })
      .catch(err => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
  }

  handleDeadLine = (deadline) => {
    new Date(deadline).getTime() > new Date().getTime() ? this.setState({ isDeadline: true }) : this.setState({ isDeadline: false })
  }

  handleSignForbidden = (signForbidden) => {
    this.setState({ isSignForbidden: signForbidden })
  }

  setSimplySignPinNo = (simplySignPinNo) => {
    this.setState({
      simplySignPinNo: simplySignPinNo,
      modalSimplySignPin: { active: false }
    })
    this.initializeSimplySignSigning()
  }

  isDocumentSignable(document, documentBillingType) {
    return document && document.permissions.canSign && documentBillingType
  }

  checkIfSigningAllowed() {
    const { t, user } = this.props
    const { document, documentBillingType, isSignaturesSufficient, isDeadline, isSignForbidden } = this.state
    const showSignaturesCTA = documentBillingType === DOCUMENT_BILLING_TYPE_SIGNER && !isSignaturesSufficient
    const currentDate = new Date()
    const deadlineDateCheck = typeof isDeadline === 'undefined' ? currentDate.getTime() < new Date(document.deadline).getTime() : isDeadline
    const signForbiddenCheck = typeof isSignForbidden === 'undefined' ? document.signForbid : isSignForbidden
    const notConfirmedEmail = user.emailNotConfirmed ? user.emailNotConfirmed : user.companyUserEmailNotConfirmed ? user.companyUserEmailNotConfirmed : null
    let err

    if (showSignaturesCTA) {
      err = t('user.document.not_enough_signatures')
      this.onModalGetSignatures()
    } else if (signForbiddenCheck === true && deadlineDateCheck === false) {
      err = t('user.document.deadline_has_passed')
    } else if (notConfirmedEmail) {
      workspaceService.resendConfirmationEmail(notConfirmedEmail)
      this.showEmailConfirmationModal()
      err = t('user.email_is_not_confirmed')
    } else if (document.format === 'xml' && user.authMethod !== AUTH_TYPE_SIMPLYSIGN) {
      err = t('simply_sign.simply_Sign_user_can_sign_xml')
    } else if (!this.isDocumentSignable(document, documentBillingType)) {
      err = t('user.document.signing_is_not_allowed')
    }

    return err ? Promise.reject(new Error(err)) : Promise.resolve()
  }

  initializeDocumentSigning() {
    const { user, history } = this.props
    const { document } = this.state
    const selectedDocuments = [document]

    if (user.authMethod === AUTH_TYPE_SMARTCARD) {
      const selectedAllCanBeSigned = document.permissions.canSign && document.format === DOCUMENT_FORMAT_PDF

      history.push({
        pathname: generatePath(ROUTE_NAMES.USER_USB_DOCUMENTS),
        state: { selectedDocuments, selectedAllCanBeSigned }
      })
      return
    }

    if (user.authMethod === AUTH_TYPE_SIMPLYSIGN) {
      this.initializeSimplySignSigning()
      return
    }

    if (user.authMethod === AUTH_TYPE_ZEALID) {
      this.props.initializeZealIdSigning()
      return
    }

    if (user.authMethod !== AUTH_TYPE_SMARTID && user.authMethod !== AUTH_TYPE_MOBILEID && user.authMethod !== AUTH_TYPE_LTID) {
      throw new Error('Unknown sign method')
    }

    this.setState({ isSigning: true })
    documentService
      .initializeSigning(this.getDocumentId())
      .then(data => documentService.pollControlCode(this.getDocumentId(), { token: data.session_id }, controlCodeTimeLimit).promise)
      .then(data => {
        const pollHandle = documentService.pollSignStatus(this.getDocumentId(), { token: data.session_id }, controlCodeTimeLimit)

        this.showModalControlCode(user.authMethod, data.control_code, () => {
          this.setState({ isSigning: false })
          this.hideModalControlCode()
          pollHandle.cancel()
        })

        return pollHandle.promise
      })
      .then(this.handleSigningSuccess)
      .catch(this.handleSigningError)
      .finally(this.finalizeSigning)
  }

  initializeSimplySignSigning() {
    const { simplySignPinNo } = this.state

    if (simplySignPinNo === undefined) {
      this.setState({ modalSimplySignPin: { active: true } })
      return
    }

    documentService
      .initializeSigning(this.getDocumentId(), { simplySignPinNo: simplySignPinNo })
      .then(data => {
        this.setState({ isSimplySignLoading: true })

        return documentService.pollSignStatus(this.getDocumentId(), { token: data.session_id }, controlCodeTimeLimit).promise
      })
      .then(this.handleSigningSuccess)
      .catch(this.handleSigningError)
      .finally(this.finalizeSigning)
  }

  handleSigningSuccess(data) {
    const { updateFirstSignatureStatus, t } = this.props

    this.setState({ isSimplySignLoading: false })
    this.hideModalControlCode()
    this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.you_have_successfully_signed_this_document'))
    updateFirstSignatureStatus()

    return new Promise(res => {
      setTimeout(() => {
        if (data.targetPath) {
          window.top.location.replace(data.targetPath)
        }

        res()
      }, 2000)
    })
  }

  handleSigningError(err) {
    const { isSigning } = this.state

    if (!isSigning) { return } // Signing cancelled

    this.hideModalControlCode()
    this.showStatusMessage(SM_TYPE_ERROR, err.message)

    if (err.response && err.response.data.error_code === 40012) {
      setTimeout(() => {
        window.location.href = generatePath(ROUTE_NAMES.USER_LOGOUT)
      }, 1000)
    }
  }

  finalizeSigning() {
    this.setState({ isSigning: false })
    this.fetchDocumentInfo()
    this.fetchDocumentHistory()
    this.props.fetchUser()
  }

  addSigner([name, surname, email, showBusinessPersonName, purpose, phone, language]) {
    const { t } = this.props
    this.setState({ isSaving: true })

    documentService
      .inviteSigner(this.state.document.uuid, { name, surname, email, showBusinessPersonName, purpose, phone, language })
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signer_was_invited'))
        this.fetchDocumentInfo()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ modalShareActive: false, isSaving: false }))
  }

  addSigners(signers) {
    const { t } = this.props

    documentService
      .inviteSigners(this.state.document.uuid, { signers: signers })
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signer_was_invited'))
        this.fetchDocumentInfo()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ modalShareActive: false, isSaving: false }))
  }

  addComment([commentaryText, access]) {
    const { t } = this.props

    documentService
      .addComment(this.state.document.uuid, { commentaryText, access })
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.comment_was_successfully_added'))
        this.fetchDocumentComments()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  removeComment(id) {
    const { t } = this.props

    documentService
      .removeComment(id)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.comment_was_successfully_removed'))
        this.fetchDocumentComments()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  migrateDocument([id]) {
    const { t } = this.props

    documentService
      .migrateDocument(this.state.document.uuid, id)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_migrated'))
        setTimeout(() => {
          this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_DOCUMENTS) })
        }, 1000)
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ modalMigrateDocumentActive: false }))
  }

  migrateDocumentToWorkspace(workspaceId, isBusiness) {
    const { t } = this.props

    documentService
      .migrateDocumentToWorkspace(this.state.document.uuid, workspaceId, isBusiness)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_migrated'))
        setTimeout(() => {
          this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_DOCUMENTS) })
        }, 1000)
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ modalMigrateDocumentActive: false }))
  }

  updateMember(member, data) {
    const { document: { members } } = this.state

    const memberToUpdate = members.find(mem => mem.id === member.id)
    memberToUpdate.canResendInvitation = data.canResendInvitation

    this.setState(prevState => ({
      document: {
        ...prevState.document,
        members,
      }
    }))
  }

  isContentShrink() {
    if (!this.state.isWidget && document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
      document.querySelector('#side-document-preview').classList.toggle('shrink')
      document.querySelector('#bottom-bar').classList.toggle('shrink')
    }
  }

  assignCategoriesToDocument(categories) {
    const { t } = this.props

    const selectedCategories = []

    categories.forEach(category => {
      if (category.selected) {
        selectedCategories.push(category)
      }
    })

    if (selectedCategories.length > 0) {
      return apiClient
        .post(`v1/${getUserWorkspace()}/document/assign-category/${this.state.document.uuid}`, {
          categories: selectedCategories
        })
        .then(() => {
          this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.categories_were_successfully_assigned'))
          this.fetchDocumentInfo()
        })
        .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
        .finally(() => {
          this.setState({ modalAssignDocumentCategoryActive: false })
        })
    } else {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.no_category_selected'))
    }
  }

  unassignDocumentCategory(category) {
    const { t } = this.props

    return apiClient
      .post(`v1/${getUserWorkspace()}/document/unassign-category/${this.state.document.uuid}/${category.id}`)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_unassigned'))
        this.fetchDocumentInfo()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  fetchCompanies() {
    workspaceService
      .getWorkspaces(this.props.user)
      .then(data => this.setCompanies(data))
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  setCompanies(data) {
    const { data: companies } = data

    this.setState({
      companies,
    })
  }

  componentDidMount() {
    this.isContentShrink()
  }

  addPurposeInMember(index) {
    const members = [...this.state.document.members]
    this.setState({ isSaving: true })
    return ((purpose) => {
      members[index].purpose = purpose
      documentService
        .updateDocumentMemberPurpose(this.state.document.uuid, {
          email: members[index].email,
          purpose: members[index].purpose
        })
        .then(({ data }) => {
          this.setState({ isSaving: false })
          this.showStatusMessage(SM_TYPE_SUCCESS, data.message)
          this.setState(prevState => {
            return {
              ...prevState,
              document: {
                ...prevState.document,
                members
              }
            }
          })
          this.fetchDocumentInfo()
        })
        .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
        .finally(() => {
          this.fetchDocumentHistory()
        })
    })
  }

  updateDocumentMemberPhone(documentId, changedPhone) {
    documentService.updateDocumentMemberPhone(documentId, { changedPhone: changedPhone })
      .then(() => {
        this.fetchDocumentInfo()
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  render() {
    const { t, user } = this.props
    const { document, isSigning, isWidget, isSaving, modalValidationLimitActive, documentBillingType, documentErrors } = this.state
    const canSign = this.isDocumentSignable(document, documentBillingType)

    return (
      <React.Fragment>
        <main id="preview" className={this.state.isWidget ? 'widget-shrink' : ''}>
          <section>
            <div className="grid">
              <div className="row">
                <div className="col md-6-12">
                  <AppHeader className="header--mobile">
                    {!isWidget ?
                      <Link
                        className="header__page-navigation"
                        to={{
                          pathname: generatePath(ROUTE_NAMES.USER_DOCUMENTS),
                          previousSearchData: this.props.location.searchValue
                        }}
                      >
                        {t('user.document.all_documents')}
                      </Link> : null}
                    {!isWidget ?
                      <div className="show-tablet">
                        <ControlButtonsDocument
                          t={t}
                          showRemove={document && document.permissions.canDelete}
                          showShare={true}
                          canValidate={true}
                          showDownload={true}
                          showMigrate={document && document.permissions.canMigrate}
                          onDownloadClick={this.onDocumentDownloadClick}
                          onShareClick={this.onDocumentShareClick}
                          onRemoveClick={this.onDocumentRemoveClick}
                          onMigrateClick={this.onDocumentMigrateClick}
                          onValidateDocumentClick={this.onValidateDocumentClick}
                        />
                      </div> : null}
                  </AppHeader>
                  {0 < documentErrors.length && (
                    <SectionErrorInformation errors={documentErrors} />
                  )}
                  {document && (
                    <SectionDocumentInformation
                      t={t}
                      document={document}
                      showAccess={true}
                      showDeadline={true}
                      showCategories={true}
                      canEdit={true}
                      showStatusMessage={this.props.showStatusMessage}
                      visibleDocumentCategories={this.props.user.visibleDocumentCategories}
                      assignCategoriesToDocument={this.assignCategoriesToDocument}
                      unassignDocumentCategory={this.unassignDocumentCategory}
                      user={this.props.user}
                      deadline={this.state.deadline}
                    />
                  )}
                  {document && (
                    <Members
                      user={this.props.user}
                      documentId={document.uuid}
                      members={document.members}
                      uploadedSignedMembers={document.uploadedSignedMembers}
                      owner={document.owner}
                      access={document.access}
                      isOwnerSigned={document.isOwnerSigned}
                      canInviteSigner={document.permissions.canInviteSigner}
                      isSigningOrderImportant={document.isSigningOrderImportant}
                      addSigner={this.addSigner}
                      addSigners={this.addSigners}
                      updateMember={this.updateMember}
                      removeMember={this.onRemoveMemberClick}
                      showStatusMessage={this.props.showStatusMessage}
                      isBusinessPerson={!!this.props.user.workspaceId}
                      purpose={this.state.purpose}
                      addPurposeInMember={this.addPurposeInMember}
                    />
                  )}
                  {document && document.isOwner && (
                    <AdditionalPerks
                      user={user}
                      documentId={document.uuid}
                      showStatusMessage={this.props.showStatusMessage}
                      deadline={document.deadline}
                      signForbid={document.signForbid}
                      reminderFrequency={document.reminderFrequency}
                      onSelectDeadLine={this.handleDeadLine}
                      onSelectReminderFrequency={this.handleSignForbidden}
                      viaSMS={document.reminderType === 3 || document.reminderType === 1}
                      viaEmail={document.reminderType === 3 || document.reminderType === 2}
                      fetchDocumentInfo={this.fetchDocumentInfo}
                      runDeadlineCheck={true}
                      members={document.members}
                      updateDocumentMemberPhone={this.updateDocumentMemberPhone}
                    />
                  )}
                  <History
                    t={t}
                    historyRecords={this.state.historyRecords}
                    displayHeadline={true}
                    displayShowLess={true}
                  />
                  {document && (
                    <DocumentComments
                      t={this.props.t}
                      document={document}
                      documentComments={this.state.documentComments}
                      addComment={this.addComment}
                      removeComment={this.removeComment}
                      user={this.props.user}
                    />
                  )}
                </div>
                <div className="col md-6-12">
                  <div id="side-document-preview" className={isWidget ? 'shrink' : ''}>
                    <div id="side-document-preview" className="preview__document-box">
                      {!isWidget && (
                        <div className="preview__controls">
                          <ControlButtonsDocument
                            t={t}
                            showRemove={document && document.permissions.canDelete}
                            showView={true}
                            showShare={true}
                            showDownload={true}
                            canValidate={true}
                            showMigrate={document && document.permissions.canMigrate}
                            onViewClick={this.onDocumentViewClick}
                            onDownloadClick={this.onDocumentDownloadClick}
                            onShareClick={this.onDocumentShareClick}
                            onRemoveClick={this.onDocumentRemoveClick}
                            onMigrateClick={this.onDocumentMigrateClick}
                            onValidateDocumentClick={this.onValidateDocumentClick}
                          />
                        </div>
                      )}
                      <p>{t('user.document.document_preview')}</p>
                      {document && (
                        <DocumentViewer
                          document={document}
                          showStatusMessage={this.props.showStatusMessage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <StatusMessages />
        <div id="bottom-bar" className={`bottom-bar ${this.state.isWidget ? 'widget-shrink' : ''}`}>
          {document && !document.documentConfirmMemberDetails.isDocumentConfirmer && !document.documentReviewMemberDetails.isDocumentReviewer &&
            <button
              className={`btn btn--primary ${isSigning || isSaving ? 'loading' : ''}`}
              disabled={isSigning || isSaving || !canSign}
              onClick={this.onSignButtonClick}
            >
              {t('user.document.sign_document')}
            </button>
          }
          {document && document.documentReviewMemberDetails.isDocumentReviewer &&
          <button
            className={`btn btn--primary ${isSigning || isSaving ? 'loading' : ''}`}
            disabled={document.documentReviewMemberDetails.alreadyReviewed || !canSign}
            onClick={this.onReviewButtonClick}
          >
            {t('user.document.mark_as_reviewed')}
          </button>
          }
          <Link className="btn btn--secondary" to={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD)}>
            {t('plus_upload_document')}
          </Link>
          {document && document.documentConfirmMemberDetails.isDocumentConfirmer &&
          <>
            <button
              className={`btn btn--primary ${isSigning || isSaving ? 'loading' : ''}`}
              disabled={document.documentConfirmMemberDetails.alreadyConfirmed}
              onClick={this.onConfirmButtonClick}
            >
              {t('user.document.confirm_document')}
            </button>
            { !(document.documentConfirmMemberDetails.alreadyDenied || document.documentConfirmMemberDetails.alreadyConfirmed) &&
            <button
              className="btn btn--red"
              onClick={this.onDenyButtonClick}
            >
              {t('user.document.deny_document')}
            </button>
            }
          </>
          }
        </div>
        <ModalAdditionalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalSignatures.active}
          onCloseClick={this.closeAdditionalSignaturesModal}
          signaturesProducts={findSignatureProducts(this.props.signatureProducts, user.subscription)}
          onBuyClick={this.onBuyClick}
        />
        <ModalControlCode
          t={t}
          active={this.state.modalControlCode.active}
          type={this.state.modalControlCode.authType}
          controlCode={this.state.modalControlCode.controlCode}
          timeLimit={controlCodeTimeLimit}
          onCloseClick={this.state.modalControlCode.closeCallback}
        />
        <ModalEmailConfirmation
          t={t}
          active={this.state.modalEmailConfirmation.active}
          onCloseClick={this.state.modalEmailConfirmation.closeCallback}
        />
        {document && (
          <ModalZealIdQrCode
            t={t}
            active={this.props.modalZealIdDocumentSign.active}
            qrHtml={this.props.modalZealIdDocumentSign.qrHtml}
            type={this.props.modalZealIdDocumentSign.type}
            onCloseClick={this.props.modalZealIdDocumentSign.closeCallback}
            onCodeReceive={this.props.onZealIdCodeReceive}
          />
        )}

        <ModalRemove
          active={this.state.modalRemoveActive}
          title={t('user.document.remove_document')}
          message={t('user.document.are_you_sure_you_want_to_remove')}
          onCloseClick={() => this.setState({ modalRemoveActive: false })}
          onRemoveClick={this.onModalRemoveDocumentClick}
        />
        <ModalRemove
          active={this.state.modalRemoveMember.active}
          title={t('user.document.remove_document_signer')}
          message={t('user.document.are_you_sure_you_want_to_remove_this_signer')}
          onCloseClick={() => this.setState({ modalRemoveMember: { active: false } })}
          onRemoveClick={() => this.removeMember(this.state.modalRemoveMember.documentMemberIndex)}
        />
        {document && (
          <ModalShare
            size="small"
            active={this.state.modalShareActive}
            access={document.access}
            documentUuid={document.uuid}
            title={t('share_document')}
            addSigner={this.addSigner}
            addSigners={this.addSigners}
            onCloseClick={() => this.setState({ modalShareActive: false })}
            showStatusMessage={this.props.showStatusMessage}
          />
        )}
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        <ModalDocumentViewer
          t={t}
          document={document}
          showStatusMessage={this.props.showStatusMessage}
          active={this.state.modalPdfViewActive}
          onCloseClick={() => this.setState({ modalPdfViewActive: false })}
          title={t('user.document.document_preview')}
        />
        <ModalDocumentMigrationCompany
          t={t}
          title={t('user.document.migrate_document_to_other_account')}
          onCloseClick={() => this.setState({ modalMigrateDocumentActive: false })}
          user={this.props.user}
          allWorkspaces={this.props.allWorkspaces}
          active={this.state.modalMigrateDocumentActive}
          migrateDocument={this.migrateDocument}
          migrateDocumentToWorkspace={this.migrateDocumentToWorkspace}
          companies={this.state.companies}
        />
        <ModalSignaturesCta
          openModalAdditionalSignatures={this.onCtaBuyAdditionalSignatures}
          active={this.state.modalSignaturesCtaActive}
          onCloseClick={() => this.setState({ modalSignaturesCtaActive: false })}
          user={user}
        />
        <ModalValidationLimitReached
          history={this.props.history}
          t={t}
          openModalAdditionalSignatures={this.onCtaBuyAdditionalSignatures}
          active={modalValidationLimitActive}
          onCloseClick={() => this.setState({ modalValidationLimitActive: false })}
          maxValidationslimit={user.validation.allowed}
        />
        <ModalRejectReason
          t={t}
          active={this.state.modalDenyDocumentConfirmation.active}
          onCloseClick={() => this.setState({ modalDenyDocumentConfirmation: { active: false } })}
          improveMessage={t('user.document.deny_document_reason')}
          onRejectClick={this.onModalDenyDocumentConfirmationSubmit}
        />
        <Modal
          active={this.state.isSimplySignLoading}
          closable={false}
        >
          <Loader loadingText={t('common.loading')} />
        </Modal>
        <ModalSimplySignPin
          t={t}
          active={this.state.modalSimplySignPin.active}
          onCloseClick={() => this.setState({ modalSimplySignPin: { active: false } })}
          title={t('simply_sign.pin_of_simply_card')}
          setSimplySignPinNo={this.setSimplySignPinNo}
        />
      </React.Fragment>
    )
  }
}

DocumentPreview.propTypes = {
  match: PropTypes.object,
  showStatusMessage: PropTypes.func,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
  t: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  fetchUser: PropTypes.func,
  location: PropTypes.object,
  allWorkspaces: PropTypes.array,
  signatureProducts: PropTypes.array,
  initializeZealIdSigning: PropTypes.func,
  modalZealIdDocumentSign: PropTypes.object,
  onZealIdCodeReceive: PropTypes.func,
  updateFirstSignatureStatus: PropTypes.func,
}

export default (withZealIdQrHtml(DocumentPreview))
