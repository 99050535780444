import React from 'react'
import ContactsAssignModalList, { ContactAssign } from '../ContactsAssignModalList'
import Modal from '../Modal'
import SimpleBar from 'simplebar-react'
import InputSearch from '../InputSearch'
import PropTypes from 'prop-types'

export default class ModalAssignContacts extends React.Component {
  countSelects(select) {
    const countSelects = this.props.contacts.filter(contact => contact.selected === select)
    return countSelects.length
  }

  render() {
    const { t } = this.props

    return (
      <Modal {...this.props}>
        <div className="center-element popup__assign--subtitle">
          <p>{this.props.contactCategoryName}</p>
        </div>
        <div className="popup__filter center-element">
          <form onSubmit={e => e.preventDefault()}>
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.props.searchValue}
                onChange={this.props.onSearchChange}
                wait={this.props.wait}
              />
            </div>
          </form>
        </div>
        <div className="popup__list popup__list--with-checkbox">
          <SimpleBar style={{ maxHeight: 280 }}>
            <ContactsAssignModalList>
              {this.props.contacts.map((contact, index) => (
                <ContactAssign
                  key={contact.id}
                  id={contact.id}
                  name={contact.name}
                  surname={contact.surname}
                  email={contact.email}
                  onSelect={this.props.onContactSelect.bind(this, index)}
                  selected={contact.selected}
                />
              ))}
            </ContactsAssignModalList>
          </SimpleBar>
        </div>
        <div className="center-element">
          <a className="btn btn--primary btn--assign" onClick={() => this.props.assignContactsToCategory(this.props.contacts)}>
            {t('user.settings.contact.assign')} {this.countSelects(true)} {t('user.settings.contact.contacts')}
          </a>
        </div>
      </Modal>
    )
  }
}

ModalAssignContacts.propTypes = {
  contacts: PropTypes.array,
  t: PropTypes.func,
  contactCategoryName: PropTypes.string,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func,
  wait: PropTypes.number,
  onContactSelect: PropTypes.func,
  assignContactsToCategory: PropTypes.func
}

ModalAssignContacts.defaultProps = {
  wait: 500,
}

