import PropTypes from 'prop-types'

export default function UsbDocumentInformation({ t, document }) {
  return (
    <div className="usb-document-information">
      <ul>
        <li>
          <p className="usb-document-information__title smallFont">
            {t('common.title')}
          </p>
          <p className="usb-document-information__name mediumFont">
            {document.fileName}
          </p>
        </li>
        <li>
          <p className="usb-document-information__title smallFont">
            {t('common.document_uploader')}
          </p>
          <p className="usb-document-information__name mediumFont">
            {document.documentUploader}
          </p>
        </li>
        <li>
          <p className="usb-document-information__title smallFont">
            {t('common.document_type')}
          </p>
          <p className="usb-document-information__name mediumFont">
            {document.format.toUpperCase()}
          </p>
        </li>
      </ul>
    </div>
  )
}

UsbDocumentInformation.propTypes = {
  t: PropTypes.func,
  document: PropTypes.object,
}