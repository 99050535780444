import React from 'react'
import Modal from '../Modal'
import SimpleBar from 'simplebar-react'
import InputSearch from '../InputSearch'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserWorkspace } from '~/js/routes/router'
import PropTypes from 'prop-types'

export default class ModalAssignDocumentCategory extends React.Component {
  constructor(props) {
    super(props)

    this.addNewDocumentCategory = this.addNewDocumentCategory.bind(this)
  }

  addNewDocumentCategory([categoryName]) {
    const { t } = this.props

    return apiClient
      .post(`/v1/${getUserWorkspace()}/document/category`, {
        name: categoryName,
      })
      .then(res => {
        return res.data
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_added')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        this.props.fetchCategories()
      })
  }

  render() {
    const { t } = this.props

    return (
      <Modal {...this.props}>
        <div className="popup__filter center-element">
          <form onSubmit={e => e.preventDefault()}>
            <div className="form-wrap form-wrap--search">
              <InputSearch
                value={this.props.searchValue}
                onChange={this.props.onSearchChange}
                wait={this.props.wait}
              />
              {
                this.props.searchValue
                && this.props.companyUserIndex === undefined
                && this.props.canCreateCategory
                && (
                  <a
                    className="btn btn--icon btn--add" onClick={() => this.addNewDocumentCategory([this.props.searchValue])}
                    id="create-new-category" style={{ marginLeft: '15px', maxWidth: '500px' }}
                  >
                    {t('user.document.create_new_category', { query: this.props.searchValue })}
                  </a>
                )}
            </div>
          </form>
        </div>
        <div className="popup__list popup__list--with-checkbox popup__list--document-categories">
          <SimpleBar style={{ maxHeight: 280 }}>
            <ul>
              {this.props.documentCategories.map((category, index) => (
                <li key={category.id}>
                  <div className="form-group__checkbox form-group__checkbox--single">
                    <input
                      className="checkbox__contact" type="checkbox" id={category.id}
                      checked={category.selected} onChange={this.props.onCategorySelect.bind(this, index)}
                    />
                    <label htmlFor={category.id} />
                  </div>
                  <span className="list__name" onClick={this.props.onCategorySelect.bind(this, index)} >
                    <strong>{category.name}</strong>
                  </span>
                </li>
              ))}
            </ul>
          </SimpleBar>
        </div>
        <div className="center-element">
          <a
            className="btn btn--primary btn--assign"
            onClick={() => this.props.companyUserIndex !== null ?
              this.props.assignCategory(this.props.documentCategories, this.props.companyUserIndex) :
              this.props.assignCategory(this.props.documentCategories)
            }
          >
            {t('user.document.assign_categories')}
          </a>
        </div>
      </Modal>
    )
  }
}

ModalAssignDocumentCategory.defaultProps = {
  wait: 500,
}

ModalAssignDocumentCategory.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  fetchCategories: PropTypes.func,
  onSearchChange: PropTypes.func,
  onCategorySelect: PropTypes.func,
  assignCategory: PropTypes.func,
  wait: PropTypes.number,
  documentCategories: PropTypes.array,
  searchValue: PropTypes.string,
  companyUserIndex: PropTypes.number,
  canCreateCategory: PropTypes.bool,
}
