import React from 'react'
import Login from '~/js/containers/LoginRoute'
import SmartCard from '~/img/icons/smartCard.svg'
import * as userService from '~/js/services/user/user'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { generatePath } from '~/js/routes/router'
import { ROUTE_NAMES } from '~/js/routes/config'
import { SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import { Redirect } from 'react-router-dom'

class SmartCardLogin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectTo: null,
    }

    this.handleSmartCardLogin = this.handleSmartCardLogin.bind(this)
  }

  componentDidMount() {
    const { location, history } = this.props

    if (!location || !location.state || !location.state.certificate) {
      history.push(generatePath(ROUTE_NAMES.USER_LOGIN))
    }
  }

  handleSmartCardLogin(loginType, values, { setSubmitting }) {
    const { showStatusMessage, location, setSessionId, setSerialNumber } = this.props
    const { certificate, country } = location.state
    let sessionId

    userService.loginSmartCardUsb({ country, certificate: certificate.encoded })
      .then(({ hash, token }) => {
        sessionId = token

        return userService.loginSmartCardUsbResponse(values.devicePassword, { hash })
      })
      .then(data => {
        return data.success === 'true'
          ? userService.loginSmartCardUsbStatus(sessionId, { signature: data.signatures[0].signedHash })
          : Promise.reject(data.error)
      })
      .then(data => {
        setSessionId(sessionId)
        setSerialNumber(data.certificate.subject.serial_number)
        setTimeout(() => {
          this.setState({ redirectTo: generatePath(ROUTE_NAMES.USER_WORKSPACES) })
        }, 1000)
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => setSubmitting(false))
  }

  render() {
    const { t, location, history } = this.props
    const { redirectTo } = this.state

    if (redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }

    return (
      <Login
        {...this.props}
        handleSmartCardLogin={this.handleSmartCardLogin}
        title={t('user.login.login_smart_card')}
        imgSrc={SmartCard}
        btnSubmitText={t('login')}
        loginMode="smartCard"
        authType={userService.AUTH_TYPE_SMARTCARD}
        location={location}
        history={history}
      />
    )}
}

export default withTranslation()(SmartCardLogin)
SmartCardLogin.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  showStatusMessage: PropTypes.func,
  setSessionId: PropTypes.func,
  setSerialNumber: PropTypes.func,
}
