// @flow

export const DOCUMENT_CAT_ALL = 'all'
export const DOCUMENT_CAT_MINE = 'mine'
export const DOCUMENT_CAT_WAITING_OTHERS = 'waiting-others'
export const DOCUMENT_CAT_WAITING_ME = 'waiting-me'
export const DOCUMENT_CAT_ALL_COMPANY = 'all-company'
export const DOCUMENT_CATS: string[] = Object.freeze([
  DOCUMENT_CAT_ALL,
  DOCUMENT_CAT_MINE,
  DOCUMENT_CAT_WAITING_OTHERS,
  DOCUMENT_CAT_WAITING_ME,
  DOCUMENT_CAT_ALL_COMPANY
])
export const DOCUMENT_ACCESS_PUBLIC = 'public'
export const DOCUMENT_ACCESS_PRIVATE = 'private'
export const DOCUMENT_ACCESS: string[] = Object.freeze([
  DOCUMENT_ACCESS_PUBLIC,
  DOCUMENT_ACCESS_PRIVATE
])
export const DOCUMENT_STATUS_UPLOADED = 'uploaded'
export const DOCUMENT_STATUS_SAVED = 'saved'
export const DOCUMENT_STATUS_SAVED_DRAFT = 'saved_draft'
export const DOCUMENT_STATUS_SIGNED_PARTIALLY = 'signed_partially'
export const DOCUMENT_STATUS_SIGNED = 'signed'
export const DOCUMENT_UPLOAD_TYPE_VALIDATION = 'validation'
export const DOCUMENT_SIGNER_STATUS_REVIEWING = 'reviewing'
export const DOCUMENT_SIGNER_STATUS_PENDING_REVIEW = 'pending_review'
export const DOCUMENT_SIGNER_STATUS_REVIEWED = 'reviewed'
export const DOCUMENT_UPLOAD_TYPE_UPLOAD = 'upload'
export const DOCUMENT_SIGNER_STATUS_PENDING = 'pending'
export const DOCUMENT_SIGNER_STATUS_SIGNED = 'signed'
export const DOCUMENT_SIGNER_STATUS_WILL_BE_INVITED = 'will_be_invited'
export const DOCUMENT_SIGNER_STATUS_REJECTED = 'rejected'
export const DOCUMENT_SIGNER_STATUS_CONFIRMED = 'confirmed'
export const DOCUMENT_SIGNER_STATUS_DENIED = 'denied'
export const SIGNATURE_INTEGRITY_VALID = 'valid'
export const SIGNATURE_INTEGRITY_INVALID = 'invalid'
export const SIGNATURE_INTEGRITY_NOT_SIGNED = 'not_signed'
export const SIGNATURE_INTEGRITY_HAS_INVALID_SIGNATURE = 'has_invalid_signature'
export const SIGNATURE_INTEGRITY_HAS_UNTRUSTED_TIMESTAMP = 'has_untrusted_timestamp'
export const DOCUMENT_COMMENT_ACCESS_PRIVATE = 'private'
export const DOCUMENT_COMMENT_ACCESS_PUBLIC = 'public'
export const DOCUMENT_FORMAT_PDF = 'pdf'
export const DOCUMENT_FORMAT_ADOC= 'adoc'
export const DOCUMENT_FORMAT_ASICE = 'asice'
export const DOCUMENT_FORMAT_BDOC = 'bdoc'
export const DOCUMENT_FORMAT_XML = 'xml'
export const DOCUMENT_CONTAINER_FORMATS: string[] = Object.freeze([
  DOCUMENT_FORMAT_ADOC,
  DOCUMENT_FORMAT_ASICE,
  DOCUMENT_FORMAT_BDOC,
])
export const DOCUMENT_VALIDATION_PURPOSE_SIGNATURE = 'signature'
export const DOCUMENT_VALIDATION_PURPOSE_VALIDATION = 'validation'
export const DOCUMENT_BILLING_TYPE_SIGNER = 1
export const DOCUMENT_BILLING_TYPE_OWNER = 2

export interface Document {
  uuid: string,
  format: string,
  fileName: string,
}