// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SM_TYPE_ERROR } from '../StatusMessages/StatusMessage'
import { getUserWorkspace } from '../../routes/router'
import * as documentService from '../../services/user/document'
import { DOCUMENT_FORMAT_PDF, DOCUMENT_FORMAT_ADOC, DOCUMENT_CONTAINER_FORMATS } from '../../models/Document'
import type { Document } from '../../models/Document'
import { getMarkSignAppHost } from '../../utils/host'
import FilesViewer from './FilesViewer'
import PdfViewer from './PdfViewer'
import ReactPdf from './ReactPdf'

type Props = {
  document: Document,
  showStatusMessage: (string, string) => void,
  className?: string,
  version?: string,
  isTemporary?: boolean,
  temporarySignLinkUuId?: string,
  httpHeaders?: {},
}

export default function DocumentViewer({
  className,
  document,
  temporarySignLinkUuId,
  showStatusMessage,
  isTemporary,
  httpHeaders,
  version,
}: Props): React.Node {
  const { t } = useTranslation()
  const [documentFiles, setDocumentFiles] = React.useState([])

  const fetchDocumentFiles = () => {
    if (!document || document.format === DOCUMENT_FORMAT_PDF) {
      setDocumentFiles([])
    } else if (isTemporary) {
      documentService
        .getFilesForTemporaryPreview(document.uuid, temporarySignLinkUuId, httpHeaders)
        .then(({ data }) => setDocumentFiles(data))
        .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
    } else {
      documentService
        .getFiles(document.uuid)
        .then(({ data }) => setDocumentFiles(data))
        .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
    }
  }

  React.useEffect(fetchDocumentFiles, [document])

  const onDownloadClick = (file) => {
    const url = `https://${getMarkSignAppHost()}/api/v1/${getUserWorkspace()}/document/${document.uuid}/files-by-path` + (isTemporary && temporarySignLinkUuId ? `/${temporarySignLinkUuId}` : '') + `?filePathInPackage=${file.filePathInPackage}`

    window.open(url, '_blank')
  }

  if (!document) {
    return null
  }

  return (
    <div className={'preview__document-content' + (className ? ` ${className}` : '')}>
      {document.format === DOCUMENT_FORMAT_PDF && !isTemporary && (
        <PdfViewer
          url={getPdfUrl(document, isTemporary, temporarySignLinkUuId)}
          version={version}
        />
      )}
      {document.format === DOCUMENT_FORMAT_PDF && isTemporary && (
        <ReactPdf
          url={getPdfUrl(document, isTemporary, temporarySignLinkUuId)}
          httpHeaders={httpHeaders}
          version={version}
        />
      )}
      {DOCUMENT_CONTAINER_FORMATS.indexOf(document.format) !== -1 && documentFiles.length > 0 && (
        <FilesViewer
          t={t}
          showMainFile={document.format === DOCUMENT_FORMAT_ADOC}
          files={documentFiles}
          onDownloadClick={onDownloadClick}
          version={version}
        />
      )}
    </div>
  )
}

function getPdfUrl(document, isTemporary, temporarySignLinkUuId) {
  return `https://${getMarkSignAppHost()}/api/v1/${getUserWorkspace()}/document/${document.uuid}/name/${document.fileName}` + (isTemporary && temporarySignLinkUuId ? `/${temporarySignLinkUuId}` : '')
}