import PropTypes from 'prop-types'
import React from 'react'
import PDF from '../../../../../img/icons/pdf.svg'
import ADOC from '../../../../../img/icons/adoc.svg'
import BDOC from '../../../../../img/icons/bdoc.svg'
import ASICE from '../../../../../img/icons/asice.svg'
import XML from '../../../../../img/icons/xml.svg'
import ChevronUp from '../../../../../img/icons/chevron-up-gray.svg'
import * as dateUtils from '~/js/utils/date'
import DocumentViewer from '../../../../components/DocumentViewer'
import UsbDocumentsTabs from '../../../../components/UsbDocumentsTabs'
import * as documentService from '../../../../services/user/document'
import * as msCoreService from '../../../../services/msCore'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import UsbSigningTabs from '~/js/components/UsbSigningTabs'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import StatusMessages from '~/js/containers/StatusMessages'
import ModalSignWithUsb from '~/js/components/ModalSignWithUsb'
import { ROUTE_NAMES } from '~/js/routes/config'
import withZealIdQrHtml from '~/js/hoc/withZealIdQrHtml'
import Loader from '~/js/components/Loader'
import {
  DOCUMENT_FORMAT_ADOC,
  DOCUMENT_FORMAT_ASICE,
  DOCUMENT_FORMAT_BDOC,
  DOCUMENT_FORMAT_PDF,
  DOCUMENT_FORMAT_XML
} from '~/js/models/Document'

const batchSize = 3

class UsbDocuments extends React.Component {
  constructor(props) {
    super(props)

    const { location } = props
    const documents = location.state?.selectedDocuments || []

    this.state = {
      documents,
      documentsToSign: this.getDocumentsToSign(documents),
      selectedAllCanBeSigned: location.state?.selectedAllCanBeSigned,
      modalPassword: { active: false },
      loading: false,
      usbCertificates: null,
      isDocumentsSigned: false,
      usbErrorMessage: null,
      totalSigned: 0,
      totalDocuments: 0,
    }

    this.onStartSigning = this.onStartSigning.bind(this)
    this.onSignDocuments = this.onSignDocuments.bind(this)
    this.hideModalPassword = this.hideModalPassword.bind(this)
    this.hasDeadlinePassed = this.hasDeadlinePassed.bind(this)
    this.signDocuments = this.signDocuments.bind(this)
    this.initializeBatchSign = this.initializeBatchSign.bind(this)
    this.completeSigningProcess = this.completeSigningProcess.bind(this)
    this.checkSigningStatus = this.checkSigningStatus.bind(this)
  }

  onStartSigning() {
    this.setState({ loading: true })

    msCoreService.checkForUsbCompatability()
      .then(({ certData }) => {
        this.setState({
          usbCertificates: certData,
          modalPassword: { active: true }
        })
      })
      .catch((error) => {
        this.setState({ usbErrorMessage: msCoreService.handleUsbCheckErrors(error) })
      })
      .finally(() => this.setState({ loading: false }))
  }

  onDocumentDownloadClick(documentId) {
    const url = `/api/v1/${getUserWorkspace()}/document/${documentId}/download`

    window.open(url, '_blank')
  }

  getDocumentsToSign(documents) {
    return documents.filter(d => d.permissions.canSign && d.format === DOCUMENT_FORMAT_PDF)
  }

  handleDocumentOpenClick(index) {
    const { showStatusMessage } = this.props
    const { documents } = this.state

    documents[index].isDocumentOpen = !documents[index].isDocumentOpen
    this.setState({ documents })

    if (documents[index].isDocumentOpen === true && typeof documents[index].members === 'undefined') {
      documentService.getInfo(documents[index].uuid)
        .then(document => {
          documents[index] = document
          documents[index].isDocumentOpen = true
          this.setState({ documents })
        })
        .then(() => documentService.getHistoryRecords(documents[index].uuid))
        .then(data => {
          documents[index].historyRecords = data
        })
        .then(() => documentService.getDocumentComments(documents[index].uuid))
        .then(data => {
          documents[index].documentComments = data
          this.setState({ documents })
        })
        .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
    }
  }

  hideModalPassword() {
    this.setState({ modalPassword: { active: false } })
  }

  onSignDocuments(password) {
    const { t, showStatusMessage } = this.props
    const { usbCertificates, documents } = this.state
    const certificate = usbCertificates.find(c => c.type === 'sign')

    if (typeof certificate === 'undefined' || !certificate) {
      const errorMessage = t('sign_certificate_was_not_found')

      this.setState({
        usbErrorMessage: {
          errorText: errorMessage,
          errorLink: false,
        },
        loading: false,
        modalPassword: {
          active: false
        }
      })

      showStatusMessage(SM_TYPE_ERROR, errorMessage)
      return
    }

    this.setState({ loading: true, totalDocuments: documents.length })

    if (this.hasDeadlinePassed(documents, new Date())) {
      showStatusMessage(SM_TYPE_ERROR, t('user.document.deadline_has_passed'))
      this.setState({ loading: false })
      return
    }

    this.setState({ batchSigningStatus: 1, password: password.newPassword, certificate: certificate })
  }

  hasDeadlinePassed(documents, currentDate) {
    for (const document of documents) {
      if (currentDate.getTime() > new Date(document.deadline).getTime() && document.signForbid === true) {
        return true
      }
    }

    return false
  }

  signDocuments() {
    const { password, certificate, documentsToSign } = this.state
    const batch = documentsToSign.splice(0, batchSize)
    let batchHashes = []

    this.initializeBatchSign(batch, certificate)
      .then(hashes => {
        const dataTobeSigned = {}
        hashes.forEach(d => dataTobeSigned[d.uuid] = d.hash)
        batchHashes = hashes

        return documentService.usbMultiSign(password, dataTobeSigned)
      })
      .then(({ signatures }) => {
        signatures.forEach(s => {
          batchHashes.find(d => d.uuid === s.id).signedHash = s.signedHash
        })

        return this.completeSigningProcess(batchHashes)
      })
      .then(() => this.checkSigningStatus(batchHashes))
      .then(() => this.setState({ batchSigningStatus: 2, documentsToSign }))
      .catch(err => {
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)

        this.setState({
          usbErrorMessage: msCoreService.handleUsbCheckErrors(err.message),
          batchSigningStatus: 3,
          loading: false,
          modalPassword: {
            active: false
          }
        })
      })
  }

  initializeBatchSign(documents, certificate) {
    const signPromises = documents.map(({ uuid }) => new Promise((res, rej) => {
      return documentService.usbSignDocument(uuid, certificate)
        .then(data => res({ token: data.session_id, sessionId: data.session_id, hash: data.hashes[0], uuid }))
        .catch(rej)
    }))

    return Promise.all(signPromises)
  }

  completeSigningProcess(documentsHashes) {
    return Promise.all(documentsHashes.map(d => documentService.completeUsbSign(d.sessionId, d.signedHash)))
  }

  checkSigningStatus(batchHashes) {
    let { totalSigned } = this.state

    return Promise.all(batchHashes.map(d => {
      return documentService.pollSignStatus(d.uuid, { sessionId: d.sessionId }, 120)
        .promise
        .then(() => this.setState({ totalSigned: ++totalSigned }))
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this
    const { t, showStatusMessage, history, fetchUser } = this.props

    if (state.batchSigningStatus !== prevState.batchSigningStatus) {
      if (state.batchSigningStatus === 1) {
        this.signDocuments()
      } else if (state.batchSigningStatus === 2) {
        if (this.state.documentsToSign.length > 0) {
          this.setState({ batchSigningStatus: 1 })
        } else {
          this.setState({ batchSigningStatus: 3, loading: false, modalPassword: { active: false } })
          showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_signed'))
          this.props.updateFirstSignatureStatus()
          fetchUser()
          history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
        }
      } else {
        history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
      }
    }
  }

  render() {
    const { t } = this.props
    const { documents, isDocumentsSigned } = this.state
    return (
      <main className="usb-documents">
        <div id="header">
          <div className="grid">
            <h1 className="page-title">{t('user.document.signing_multiple_documents')}</h1>
          </div>
        </div>
        <section className="usb-documents__documents">
          <div className="wrapped-section__headline">
            <h2 className="headline headline--standard">{t('documents')}</h2>
          </div>
          {documents.length === 0
            ? <Loader loadingText={t('usb.documents_loading')} />
            : documents.map((document, index) => (
              <div key={document.uuid} className={`usb-documents__document ${document.isDocumentOpen ? 'open' : 'closed'}`}>
                <div className="usb-documents__document--header" onClick={this.handleDocumentOpenClick.bind(this, index)}>
                  <div className="usb-documents__document--header__info">
                    <div className="document--header__info--img">
                      {document.format === DOCUMENT_FORMAT_PDF && (
                        <img src={PDF} alt="PDF" />
                      )}

                      {document.format === DOCUMENT_FORMAT_ADOC && (
                        <img src={ADOC} alt="ADOC" />
                      )}

                      {document.format === DOCUMENT_FORMAT_BDOC && (
                        <img src={BDOC} alt="BDOC" />
                      )}

                      {document.format === DOCUMENT_FORMAT_ASICE && (
                        <img src={ASICE} alt="ASICE" />
                      )}

                      {document.format === DOCUMENT_FORMAT_XML && (
                        <img src={XML} alt="XML" />
                      )}

                    </div>
                    <div className="document--header__document-info">
                      <div className="document--header__document-info--document-title">{document.displayTitle}</div>
                      <div className="document--header__document-info--date">{dateUtils.formatDateTime(document.uploadDate)}</div>
                    </div>
                  </div>
                  <div className="usb-documents__document--header__controls">
                    <button
                      className="button button--download display-desktop"
                      onClick={this.onDocumentDownloadClick.bind(this, document.uuid)}
                    >{t('download')}
                    </button>
                    <button>
                      <img className={document.isDocumentOpen ? 'open__icon' : 'closed__icon'} src={ChevronUp} alt="Chevron Up" />
                    </button>
                  </div>
                </div>
                {document &&
                  <div className="usb-documents__document--body">
                    <div className="usb-documents__document-preview">
                      <DocumentViewer
                        className="display-desktop"
                        document={document}
                        showStatusMessage={this.props.showStatusMessage}
                      />
                      <button
                        className="review-download-document display-tablet"
                        onClick={this.onDocumentDownloadClick.bind(this, document.uuid)}
                      >
                        {t('user.document.view_document')}
                      </button>
                      <UsbDocumentsTabs t={t} document={document} />
                    </div>
                  </div>
                }
              </div>
            ))
          }
        </section>
        <section className="usb-documents__information">
          <div className="wrapped-section__headline">
            <h2 className="headline headline--standard">{t('common.signing_methods')}</h2>
          </div>
        </section>
        <section className="usb-documents__signing-tabs">
          <UsbSigningTabs
            t={t}
            isDocumentsSigned={isDocumentsSigned}
            loading={this.state.loading}
            onStartSigning={this.onStartSigning}
            usbErrorMessage={this.state.usbErrorMessage}
            selectedAllCanBeSigned={this.state.selectedAllCanBeSigned}
          />
        </section>
        <StatusMessages />
        <ModalSignWithUsb
          t={t}
          active={this.state.modalPassword.active}
          addSigner={this.inviteSigner}
          onCloseClick={this.hideModalPassword}
          onSignDocuments={this.onSignDocuments}
          loading={this.state.loading}
          totalSigned={this.state.totalSigned}
          total={this.state.totalDocuments}
        />
      </main>
    )
  }
}

UsbDocuments.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  updateFirstSignatureStatus: PropTypes.func,
}

export default (withZealIdQrHtml(UsbDocuments))
